import React, {Suspense} from "react";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";

// Only selected app will be loaded, when it is needed
const AppRecruiters = React.lazy(() => import('./AppRecruiters'));
const AppB2B = React.lazy(() => import('./AppB2B'));

function App() {

  let AppComponent;

  // Select app to load depending on environment variable
  switch (process.env.REACT_APP_BUNDLE) {
    case "recruiters":
      AppComponent = AppRecruiters;
      break;
    case "b2b":
      AppComponent = AppB2B;
      break;
    default:
      AppComponent = null;
  }

  return (
    <Suspense fallback={<LoadingSpinner/>}>
      <AppComponent/>
    </Suspense>
  );
}

export default App;
