import React from 'react';
import "./LoadingSpinner.scss";
import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner() {

  return (
    <div className="LoadingSpinner">
      <Spinner animation="border" variant="secondary" role="status"/>
    </div>
  );
}

export default LoadingSpinner;
